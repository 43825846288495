<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="订单管理"/>
		<div class="nullImg" v-if="data.count  == 0 ">
			<img src="../assets/img/null02.jpg" alt="">
		</div>
		<div v-else  class="countBox">
			<el-card class="box-card" v-for="lis in data.list" :key="lis">
				<div  slot="header" class="header1" >
					<div class="box_head">
						<div class="left_head">
							<p class="order-num">订单编号：{{lis.order_sn}}</p>
							<p class="order-time">创建时间：{{lis.add_time}}</p>
						</div>
						<div class="center_head">
							<span class="order-time" >收货人：{{lis.address_name ? lis.address_name :'暂无信息'}}</span>
							<span class="order-time">联系方式：{{lis.address_mobile ? lis.address_mobile :'暂无信息'}}</span>
							<span class="order-time">收货地址：{{lis.address_address ? lis.address_address :'暂无信息'}}</span>
						</div>
						<div class="right_head">
							<el-button type="info" size="small" disabled v-if="lis.pay_state==1">已过期</el-button>
							<el-button type="success" size="small" disabled v-if="lis.pay_state==2">已付款</el-button>
							<el-button size="small" v-if="lis.pay_state==0" type="warning"
								@click="open(`https://buy.chinaxdsb.com/submit_order/${lis.id}/${lis.payment_type == '7' ? 2 : 1} `);">
								去付款</el-button>
						</div>
					</div>
					<div class="bbbox_box">
						<div class="bbbox">
							<div class="goods" v-for="order in lis.goods" :key="order.id">
								<div class="centerBox">
									<div class="cont_box same">
										<div class="cont1">
											<div class="cont3">
												<img :src="'//img.chinaxdsb.com/'+order.sku.thumb"
													@click="open(`/product/${good.goods_id}`)">
												<!-- :onerror="errorImg" -->
												<div class="b_box">
													<p class="sm">产品名称：{{order.sku.title}}</p>
													<p class="sm">型号：{{order.sku.model?order.sku.model:'暂无信息'}}</p>
													<p class="sm">单价：{{order.sku.money?order.sku.money:'暂无信息'}}</p>
													<p class="pa">数量：{{order.goods_num}}</p>
												</div>
												<div>
													<p class="pa">修改前：{{order.order_price}}元</p>
													<p class="pa">修改后：{{order.final_price}}元</p>
												</div>
												<!-- <div>
													<template slot-scope="scope">
												<p>税费：{{scope.row.shuifei}}</p>
												<p>运费：{{scope.row.yunfei}}</p>
												<p>包装费：{{scope.row.final_price}}</p>
											</template> 
												</div>-->
												<div class="pjbtn">
													<el-button  size="small"  v-if="lis.pay_status==1 && lis.order_express_code != '' &&  order.comment == 0" type="danger" plain disabled>未评价</el-button>
													<el-button  size="small"  v-else-if="lis.pay_status==1 && lis.order_express_code != '' &&  order.comment == 1" type="success" plain disabled>已评价</el-button>
													<template class="pjgj">
														<el-button type="primary" size="small"  v-if="!order.edit_price_time && lis.pay_status==0" 
															@click="changePrice(order.id)">改价&nbsp;&nbsp;
														<!-- <el-link  type="info" @click="changePrice(scope.row)">改价</el-link> -->
														</el-button>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="liuyan" v-if="order.message">
									<span class="liuy">用户留言:</span> {{order.message}}
								</div>
							</div>
						</div>
						<div class="botmbox">
							<div class="price">
								<div class="pricebox">
									<p>产品总价：{{lis.totle_amount}}</p>
									<p class="sheng">省(优惠)：{{lis.sheng}}</p>
								</div>
								<!-- 通知收款判断收货 -->
								<template class="collection" > <!-- null meifa -->
									<el-button size="small" type="danger" v-if="lis.pay_status==1 && lis.financial_sms == null || lis.financial_sms == ''" @click="goCollection(lis.id)">通知收款</el-button>
								</template>
								
							</div>
							<div class="wl">
								<div class="wlbox">
									<template v-model="lis.order_express_code" class="wlbtn" v-if="lis.order_express_code == null && lis.pay_status == 1">
										<el-button size="small" @click="toDeliver(lis.id)">去发货</el-button>
									</template>
									<template v-model="lis.order_express_code"  class="wln" v-else>
										物流信息
										<p v-model="lis.order_express_name">快递公司：{{lis.order_express_name?lis.order_express_name:'暂无信息'}}</p>
										<p v-model="lis.order_express_code">快递单号：{{lis.order_express_code?lis.order_express_code:'暂无信息'}}</p>
										<p v-model="lis.order_express_content">快递备注：{{lis.order_express_content?lis.order_express_content:'暂无信息'}}</p>
									</template>
								</div>
							</div>
							<div class="xq">
								<div class="top">
									<template>
										<el-button size="small" type="danger" v-if="lis.pay_status==0 " plain disabled>未支付</el-button>
										<el-button size="small"  v-else-if="lis.pay_status==1" type="success" plain disabled>已支付</el-button>
										<el-button size="small" v-else-if="lis.pay_status==2" type="warning" plain disabled>已过期</el-button>
										<el-button size="small" v-else-if="lis.pay_status==2 && lis.order_express_code  && lis.order_express_shou_time != '' "  plain disabled>已收货</el-button>
										<!-- <el-tag v-if="scope.row.pay_status==2" type="danger">超时</el-tag> -->
									</template>
								</div>
								<div>
									<el-button size="small"
										@click="open(`https://myseller.chinaxdsb.com/orderDetail/${lis.id}`)">详情
									</el-button>
								</div>
								<div>
									<el-button size="small" class="fpnews" @click="fpnews(lis.id)">发票信息</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-card>
		</div>
		<!-- 发货弹框 -->
		<div class="logisticsBox">
			<div class="logistics">
				<span class="logtit">请填写发货信息</span>
				<el-form ref="form" :model="form" label-width="90px" :rules="rules">
					<div class="avatar"  express_id="0">
						<el-form-item label="物流公司" style="margin-left: -10px;" prop="getExpressList">
							<el-select class="wlxx" v-model="form.getExpressList"  @change="changew" filterable placeholder="可选择/输入">
								<el-option v-for="i in getExpressList" :key="i.id" :label="i.name"
									:value="i.id" >
								</el-option>
							</el-select>
						</el-form-item>
						<div style="padding: 5px 5px;color: #9e9e9e;"  >如物流公司不存在，请在备注信息中注明所发物流信息</div>
					</div>
					<el-form-item  label="快递单号" style="margin-left: -10px;" prop="code">
						<el-input name="logcode" v-model="form.code" placeholder="请输入快递单号"></el-input>
					</el-form-item>
					<el-form-item label="备注信息" style="margin-left: -10px;">
						<el-input name="logcont" v-model="form.content" placeholder="请输入备注信息"></el-input>
					</el-form-item>
					<div class="logist_btn">
						<el-button type="primary" @click="onSubmit">确定</el-button>
						<el-button class="logist_qx" @click="cancel">取消</el-button>
						<div id="orderId" orderId=""></div>
					</div>
				</el-form>
			</div>
			<div class="black"></div>
		</div>
		<!--发票信息 -->
		<div class="invoiceBox">
			<div class="invoice">
				<span class="logtit">发票信息</span>
				<el-form ref="invoice" :model="invoice" label-width="100px">
					<!-- <div class="peo">
						<el-form-item label="个人名称" style="margin-left: -10px;">
							<el-input v-model="invoice.invoice_title"></el-input>
						</el-form-item>
					</div> -->
					<div class="comm" style="display: block;">
						<el-form-item label="发票类型" style="margin-left: -10px;">
							<el-input v-model="invoice.typea"></el-input>
						</el-form-item>
						<el-form-item label="个人/单位名称" style="margin-left: -10px;">
							<el-input v-model="invoice.invoice_title"></el-input>
						</el-form-item>
						<el-form-item label="纳税人识别号" style="margin-left: -10px;">
							<el-input v-model="invoice.invoice_no" placeholder="纳税人识别号 企业必填~"></el-input>
						</el-form-item>
						<el-form-item label="注册地址" style="margin-left: -10px;">
							<el-input v-model="invoice.register_address"  placeholder="注册地址 企业必填~"></el-input>
						</el-form-item>
						<el-form-item label="注册电话" style="margin-left: -10px;">
							<el-input v-model="invoice.register_phone"  placeholder="注册电话 企业必填~"></el-input>
						</el-form-item>
						<el-form-item label="开户银行" style="margin-left: -10px;">
							<el-input v-model="invoice.bank_name"  placeholder="开户银行 企业必填~"></el-input>
						</el-form-item>
						<el-form-item label="银行账户" style="margin-left: -10px;">
							<el-input v-model="invoice.bank_code"  placeholder="银行账户 企业必填~"></el-input>
						</el-form-item>
					</div>
					<div class="invoice_btn">
						<el-button type="primary" @click="invSubmit">关闭</el-button>
					</div>
				</el-form>
			</div>
			<div class="black"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				express_id:'',
				getExpressList: [],
				options: [],
				form: {
					getExpressList:[],
					code: '',
					content: '',
				},
				invoice: {
					typea: '',
					invoice_title: '',
					invoice_no: '',
					register_address: '',
					register_phone: '',
					bank_name: '',
					bank_code: '',
				},
				wlxx:'',
				data: [],
				p: 1,
				total: 0,
				tableData: [],
				expressList: [],
				deliverVisible: false,
				// deliver: {
				// 	express_id: '',
				// 	express_code: '',
				// 	id: ''
				// },
				buyer: {
					name: '',
					phone: '',
					youbian: '',
					address: ''
				},
				rules: {
					code: [{
						required: true,
						message: '请填写快递单号',
						trigger: "blur"
					}],
					getExpressList:[{
						required: true,
						message: '请填写物流信息',
						trigger: "change"
					}]
					
				}
			}
		},
		created() {
			this.getData()
		},
		methods: {
			//通知收款弹窗
			goCollection(id){
				const h = this.$createElement;
				this.$prompt('请填写财务手机号', '通知收款', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
					inputErrorMessage: '请填写正确号码'
				}).then(({
					value
				}) => {
					this.$axios.post('/NotifyTheReceipt', {
						id: id,
						phone: value
					}).then(data => {
						this.getData()
						$(".collection").hide()
						if(data.code == "200"){
							this.$message.success(data.msg);
						}else{
							this.$message.error(data.msg);
						}
					})
				}).catch(data =>{
					this.$message.error(data.msg);
				});
			},
			changew(value){
				$(".avatar").attr("express_id",value)
			},
			fpnews(id) { 
				$(".invoiceBox").show()
				this.$axios.post('/getInvoiceInfo', {
					id:id
				}).then(data => {
					this.invoice = data
					if(data.register_address && data.bank_code && data.bank_name && data.register_phone){
						this.invoice.typea = "增值税专用发票"
					}else{
						this.invoice.typea = "普通发票"
					}
					
				})
			},
			invSubmit() {
				$(".invoiceBox").hide()
			},
			invcancel() {
				$(".invoiceBox").hide()
			},
			cancel() {
				$(".logisticsBox").hide()
			},
			onSubmit() {
				
				let order_id = $("#orderId").attr("orderId")
				let express_id = $(".avatar").attr("express_id")
				this.$axios.post('/doDeliverGoods', {
					order_id:order_id,
					express_id:express_id,
					express_code:$("input[name=logcode]").val(),
					content:$("input[name=logcont]").val()
				}).then(data => {
					this.$message.success(data.msg)
					if(data.code == 200){
						$(".avatar").attr("express_id","")
						$(".logisticsBox").hide()
					}
					window.location.reload()
				}).catch(data => {
					this.$message.error(data.msg)
				});
				
			},
			getData() {
				this.$axios.post('/getOrder', {
					p: this.p
				}).then(data => {
					this.data = data
					this.total = Number(data.count)
					this.tableData = data.list;
					document.body.scrollTop = document.documentElement.scrollTop = 0
				})
			},
			open(url) {
				window.open(url);
			},
			currentChange(page) {
				this.p = page
				this.getData()
			},
			toDeliver(id) {
				$("#orderId").attr("orderId",id)
				$(".logisticsBox").show()
				this.$axios.post('/getExpressList').then(data => {
					this.getExpressList = data
				})
			},
			deliverSubmit() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.$axios.post('/doDeliverGoods', {
							id: this.deliver.id,
							express_id: this.deliver.express_id,
							express_code: this.deliver.express_code
						}).then(data => {
							this.getData()
							this.deliverVisible = false
							this.$message.success('发货成功')
						})
					} else {
						return false;
					}
				});
			},
			changePrice(row) {
				const h = this.$createElement;
				this.$prompt('只可以修改一次,请慎重', '改价', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^[1-9]{1}[0-9]*$|^0{1}\.{1}[0-9]+$|^[1-9]{1}[0-9]*\.{1}[0-9]+$/,
					inputErrorMessage: '价格必须大于0'
				}).then(({
					value
				}) => {
					this.$axios.post('/setorderprice', {
						id: row,
						revised_price: value
					}).then(data => {
						this.getData()
						this.$message.success('修改成功');
					})
				}).catch(() => {});
			}
		}
	}
</script>

<style scoped>
	.o-wrap{
		background-color: #fff;
		width: 1600px;
		height:98vh;
		position:relative;
	}
	.avatar{
		display: flex;
	}
	.right_head,.left_head,.center_head{
		display: flex;
	}
	.bbbox_box {
		display: flex;
	}

	.bbbox {
		width: 70%;
	}

	.box_head {
		display: flex;
		justify-content: space-between;
		height: 80px;
		line-height: 40px;
		padding: 0 10px;
		font-weight: bold;
	}

	.header1 {
		margin-top: 30px;
		box-shadow: 3px 3px 3px #ccc;
		border: 1px solid #ccc;
	}

	.cont1 {}

	.cont3 {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 30px 0;
	}

	.cont_box {}

	.cont:nth-last-child(1) {
		border-bottom: 1px solid transparent;
	}

	.price {
		/* width: 17%; */
		min-width: 100px;
		text-align: center;
		padding-top: 20px;
	}

	.order .el-card__header>div p {
		line-height: 32px;
	}

	.order-time {
		margin-left: 20px;
		margin-right: auto;
	}


	.goods {
		font-size: 14px;
		color: #3c3c3c;
		margin: 0 0 0 20px;
		border: 1px solid #ccc;
		border-top: 1px solid transparent;

	}

	.goods:nth-child(1) {
		margin-top: 0;
		border-top: 1px solid #ccc;
	}

	.goods:nth-last-child(1) {
		margin-bottom: 20px;
	}

	.goods img {
		width: 100px;
		height: 100px;
	}

	.goods>div p {
		line-height: 20px;
	}

	.goods>div p.sm {
		width: 257px;
	}

	.goods>div p.pa {}

	.order .box-card {
		width: 1560px;
		margin: 10px auto;
	}

	.order .el-card__header>div {
		display: flex;
	}

	.tit {
		display: flex;
		justify-content: space-between;
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		background: #f5f5f5;
		font-weight: bold;
	}

	.tit_left {
		display: flex;
	}

	.ph {
		margin-left: 20px;
	}

	.lx {
		margin-left: 40px;
	}

	.box_left {
		display: flex;
		justify-content: space-around;
	}

	.box_left .b_box {}

	.cont3 .b_box {}

	.dp {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.clear {
		clear: both;
	}

	.orderSellerBox {}

	.centerBox {
		/* display: flex;
		justify-content: space-between;
		
		border: 1px solid #DDD;
		border-top: 1px solid transparent; */
	}

	.same {
		border-right: 1px solid #ccc;
	}

	.same:nth-last-child(1) {
		border-right: 1px solid transparent;
	}

	.block {
		text-align: center;
		height: 50px;
		line-height: 50px;
	}

	.block p {
		text-align: center;
	}

	.btnOn {
		display: block;
	}

	.btnNo {
		display: none;
	}

	.pj {
		width: 100px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.price,
	.wl{
		min-height: 80px;
		overflow: hidden;
		border-right: 1px solid #ccc;
	}
	.xq ,.price,.wl{
		width: 33.3%;
		text-align: center;
		overflow: hidden;
	}
	.pricebox{
		padding: 20px 0;
	}
	.botmbox{
		width: 30%;
		display: flex;
		justify-content: space-around;
		margin-bottom: 20px;
		border: 1px solid #ccc;
		margin-right: 20px;
		border-left: 1px solid transparent;
	}
	.price {
		/* padding-top: 30px;
		border-left: 1px solid transparent; */
	}
	.wlbtn{
		padding-top: 10px;
	}
	.xq .top {
		padding: 10px 0;
	}

	.xq {
		
	}

	.wl {
		padding: 30px 0;
	}

	.wl p {
		/* padding-top: 10px; */
	}

	/* 发货 */
	.logistics {
		width: 500px;
		height: 320px;
		position: fixed;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: #fff;
		padding: 0px 30px;
		border-radius: 8px;
		box-shadow: 3px 3px 10px #ccc;
		z-index: 1000;
	}
	.invoice {
		width: 500px;
		/* position: absolute; */
		position: fixed;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: #fff;
		padding: 0px 30px;
		border-radius: 8px;
		box-shadow: 3px 3px 10px #ccc;
		z-index: 1000;
	}
	

	.logist_btn,
	.invoice_btn {
		/* display: flex;
		justify-content: space-around; */
		text-align: center;
	}

	.logtit {
		display: block;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		padding-top: 20px;
	}

	.black {
		position: fixed;
		width: 100%;
		height: 100%;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.logisticsBox {
		display: none;
	}

	/* 发票 */
	.invoice {
		height: 580px;
	}

	.fpnews {
		margin: 10px 0;

	}

	.invoiceBox {
		display: none;
	}
	.sheng{
		margin-top: 20px;
	}
	.wlbox p{
		margin: 8px 0;
	}
	.nullImg{
		width:600px;
		height:600px;
		position: absolute;
		left: 0;right: 0;
		top: 0;bottom: 0;
		margin: auto;
		text-align:center;
	}
	
	/* .nullImg img{
		width: 600px;
		height: 600px;
		position: absolute;
		left: 0;right: 0;
		top: 0;bottom: 0;
		margin: auto;
	} */
	.collection{
		width: 60px;
		height: 30px;
		line-height: 30px;
		border: 1px solid red;
		border-radius: 10px;
		color: red;
	}
	.liuy{
		color: #0077AA;
		font-size: 16px;
		line-height: 30px;
		padding-left: 10px;
	}
	.liuyan{
		border-top:1px solid #ccc;
	}
	.countBox{
		width: 1580px;
		margin: 0 auto;
	}
</style>
